/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import * as styles from "../styles/styles.module.css"
import Header from "./header"
import TermWidget from "./widget/term"
import YearWidget from "./widget/year"
import AuthorWidget from "./widget/author"
import ColorModeSwitch from "./ColorModeSwitch"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata.title

  return (
    <>
      <Header siteTitle={title} />
      <div className={styles.bodyLayout}>
        <main>{children}</main>

        <aside>
          <AuthorWidget />
          <TermWidget />
          <YearWidget />
        </aside>

        <footer className={styles.flexColCenter}>
          <div className={styles.footerLinks}>
            <Link to="/about/">サイトについて</Link>
            <Link to="/archive/">アーカイブ</Link>
          </div>
          <div className={styles.flexRowCenter}>
            © {new Date().getFullYear()} {title}
          </div>
          <ColorModeSwitch className={styles.colorModeSwitch} />
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
