import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { GitHub, Blog, Work, WordPress } from "../icons"
import * as styles from "../../styles/styles.module.css"
import BaseWidget from "./basewidget"

const AuthorWidget = () => {
  const size = "1.1em"
  return (
    <BaseWidget title="著者" hideTitle={true}>
      <div className={styles.authorProfile}>
        <StaticImage
          src="../../images/gotohayato.jpg"
          alt="後藤隼人"
          aspectRatio={1 / 1}
          width={110}
          className={styles.authorProfileImg}
        />
        <div className={styles.authorProfileText}>
          <p>
            <ruby>
              後藤隼人 <rp>(</rp>
              <rt>ごとうはやと</rt>
              <rp>)</rp>
            </ruby>
          </p>
          <p>
            ソフトウェア開発・サイト構築などをしています。
            お仕事のご相談・お問い合わせは
            <a href="https://contact.dyno.design">こちら</a>にください。
          </p>
          <p className={styles.authorProfileIcons}>
            <Link href="https://github.com/gh640" label="GitHub">
              <GitHub size={size} />
            </Link>
            <Link href="https://gotohayato.com/" label="ブログ">
              <Blog size={size} />
            </Link>
            <Link href="https://dyno.design/" label="仕事">
              <Work size={size} />
            </Link>
            <Link
              href="https://wordpress.dyno.design/"
              label="実践 WordPress: WordPress の実践的な使い方をご紹介しています。"
            >
              <WordPress size={size} />
            </Link>
          </p>
        </div>
      </div>
    </BaseWidget>
  )
}

const Link = ({ href, label, children }) => (
  <a
    href={href}
    rel="me"
    aria-label={label}
    className={styles.authorProfileIcon}
  >
    {children}
  </a>
)

export default AuthorWidget
