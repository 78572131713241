/**
 * 利用するアイコンを一元化して差し替えやすくするためのコンポーネント
 */
import React from "react"
import {
  FiCalendar,
  FiRotateCw,
  FiTag,
  FiGithub,
  FiEdit,
  FiZap,
  FiSearch,
  FiSun,
  FiMoon,
} from "react-icons/fi"
import { RiWordpressLine } from "react-icons/ri"

const Posted = props => {
  return <FiCalendar aria-label="投稿日" {...props} />
}

const Updated = props => {
  return <FiRotateCw aria-label="更新日" {...props} />
}

const Tag = props => {
  return <FiTag aria-label="タグ" {...props} />
}

const GitHub = props => {
  return <FiGithub {...props} />
}

const Blog = props => {
  return <FiEdit {...props} />
}

const Work = props => {
  return <FiZap {...props} />
}

const Search = props => {
  return <FiSearch {...props} />
}

const Sun = props => {
  return <FiSun {...props} />
}

const Moon = props => {
  return <FiMoon {...props} />
}

const WordPress = props => {
  return <RiWordpressLine {...props} />
}

export {
  Posted,
  Updated,
  Tag,
  GitHub,
  Blog,
  Work,
  Search,
  Sun,
  Moon,
  WordPress,
}
