exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-term-tsx": () => import("./../../../src/templates/term.tsx" /* webpackChunkName: "component---src-templates-term-tsx" */),
  "component---src-templates-year-tsx": () => import("./../../../src/templates/year.tsx" /* webpackChunkName: "component---src-templates-year-tsx" */),
  "component---src-templates-yearmonth-tsx": () => import("./../../../src/templates/yearmonth.tsx" /* webpackChunkName: "component---src-templates-yearmonth-tsx" */)
}

